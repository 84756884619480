export enum GenericEvents {
	BAG_UPDATE = 'bag_update',
	BAG_ERROR = 'bag_error',
	STOCK_TOTAL_ERROR = 'stock_total_error',
	STOCK_PARTIAL_ERROR = 'stock_partial_error',
	ADD_PROMO_CODE = 'add_promo_code',
}

export enum BagErrorTypes {
	ADD_ITEM = 'bag-error-add-item',
	REMOVE_ITEM = 'bag-error-remove-item',
	TOTAL_ITEMS = 'bag-error-total-items',
	PREVIEW_ITEMS = 'bag-error-preview-items',
	PAGE_ITEMS = 'bag-error-page-items',
	CLEAR_ITEM = 'bag-error-clear-item',
}
