import { IconDone } from 'icons/components/IconDone'
import { IconErrorL } from 'icons/components/IconErrorL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import { SnackbarPosition } from 'notifications/constants/constants'
import { useNotificationsContext } from 'notifications/hooks/useNotificationsContext'
import type { MouseEvent } from 'react'
import {
	ADD_NOTIFICATION_STORE_TIME,
	ADD_NOTIFICATION_TIME,
} from '../constants/constants'
import { AddedToCartNotification } from '../context/AddedToCartNotification/AddedToCartNotification'

import type { ItemDto } from '../services/graphql.types'
import { useAnalytics } from './useAnalytics'

export const useNotifications = () => {
	const { addNotification, removeNotification } = useNotificationsContext()
	const { t } = useLabels()
	const { sendBagUpdateEvent } = useAnalytics()

	return {
		removeNotification,
		addItemNotification: () =>
			addNotification({
				id: 'addItemNotification',
				title: t('snackbar.addToBag.confirmation.text'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				icon: <IconDone color='content-inverse' />,
				position: SnackbarPosition.TOP,
			}),
		addItemTextNotification: () =>
			addNotification({
				id: 'addItemNotification',
				title: t('snackbar.addToBag.confirmation.text'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				position: SnackbarPosition.BOTTOM,
			}),
		addItemWithNatigationNotification: ({
			onClick,
			totalItems,
		}: {
			onClick: (e: MouseEvent<HTMLButtonElement>) => void
			totalItems?: number
		}) =>
			addNotification({
				onClick,
				id: 'addItemWithNavigationNotification',
				title: t('snackbar.addToBag.title'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				buttonText: replacePlaceholders(t('snackbar.addToBag.button'), {
					1: totalItems ?? 0,
				}),
				position: SnackbarPosition.BOTTOM,
			}),
		addItemNotificationError: () =>
			addNotification({
				id: 'addItemNotificationError',
				title: t('snackbar.addToBag.error.text'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				icon: <IconErrorL color='content-inverse' />,
				position: SnackbarPosition.BOTTOM,
			}),
		itemWithoutStockNotification: () =>
			addNotification({
				id: 'itemWithoutStockNotification',
				title: t('shoppingBag.error.noStock.title'),
				subtitle: t('shoppingBag.error.noStock.bodycopy'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				icon: <IconErrorL color='content-inverse' />,
				position: SnackbarPosition.TOP,
			}),
		updateNotification: () => {
			sendBagUpdateEvent()
			addNotification({
				id: 'updateNotification',
				title: t('shoppingBag.tooltip.updatedBag.title'),
				subtitle: t('shoppingBag.tooltip.updatedBag.bodycopy'),
				delay: ADD_NOTIFICATION_TIME,
				position: SnackbarPosition.TOP,
			})
		},
		itemWithParamErrorNotification: () =>
			addNotification({
				id: 'itemWithParamErrorNotification',
				title: t('shoppingBag.error.connection.title'),
				subtitle: t('shoppingBag.error.connection.bodycopy'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				icon: <IconErrorL color='content-inverse' />,
				position: SnackbarPosition.TOP,
			}),
		deletedItemNotification: ({
			onClick,
		}: {
			onClick: (e: MouseEvent<HTMLButtonElement>) => void
		}) =>
			addNotification({
				onClick,
				id: 'deletedItemNotification',
				title: t('shoppingBag.deletedItem.snackbar.title'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				buttonText: t('snackbar.undo.button'),
				position: SnackbarPosition.BOTTOM,
			}),
		editedItemErrorNotification: ({
			onClick,
		}: {
			onClick: (e: MouseEvent<HTMLButtonElement>) => void
		}) =>
			addNotification({
				onClick,
				id: 'editedItemErrorNotification',
				title: t('snackbar.edit.error.text'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				buttonText: t('snackbar.edit.error.button'),
				position: SnackbarPosition.BOTTOM,
			}),
		addLargeNotification: ({
			childrenProps,
		}: {
			childrenProps: {
				foundItem: ItemDto
				isSmall: boolean
			}
		}) =>
			addNotification({
				id: 'cartAddedNotification',
				title: t('notification.addToBag.title'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				isSmall: childrenProps.isSmall,
				children: (
					<AddedToCartNotification foundItem={childrenProps.foundItem} />
				),
				type: 'large',
				position: SnackbarPosition.BOTTOM,
			}),
	}
}
