import type { PriceDto } from 'cart/services/graphql.types'
import type { ItemPromotion } from 'codegen/src/gateway/graphql'
import type { ProductPrices } from 'product/types/Prices'
import { Channel } from 'types/masterData'

import { handlePreviousPrices } from './handlePreviousPrices'

export const parsePriceData = (
	price: PriceDto,
	promoCode: ItemPromotion | undefined
): ProductPrices => {
	const currentPrice = price.current?.currentPrice ?? 0
	const discountRate = price.discountRate ?? 0
	const promoDiscount = promoCode?.code ? promoCode?.price?.amount : null
	const shopOriginalPrice = price.previous?.original?.shop?.amount
	const outletOriginalPrice = price.previous?.original?.outlet?.amount
	const initialPrice = price.initial?.initialPrice

	const previousPrices = {
		originalShop: handlePreviousPrices(
			shopOriginalPrice,
			undefined,
			Channel.Shop,
			promoDiscount,
			initialPrice
		),
		originalOutlet: handlePreviousPrices(
			undefined,
			outletOriginalPrice,
			Channel.Outlet,
			promoDiscount,
			initialPrice
		),
	}

	const lowestPrices = {
		sale: price.previous?.lowest?.sale?.amount ?? undefined,
		promo: price.previous?.lowest?.promo?.amount ?? undefined,
	}

	return {
		discountRate,
		previousPrices,
		lowestPrices,
		price: promoDiscount ?? currentPrice,
	}
}
