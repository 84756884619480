import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { PageType } from 'types/analytics'

import { CART_PATH } from '../constants/constants'
import { useCartDispatch, useCartState } from '../context/CartProvider'
import type { ItemDto } from '../services/graphql.types'
import { FEEDBACK_ADD_TO_CART_VARIANTS } from '../utils/mapFeedbackAddToCartVariant'
import { useNotifications } from './useNotifications'

interface NotificationHandlerProps {
	location: string
	notShowNotification?: boolean
	totalItems?: number
	getDispatch?: () => void
	variant?: string
	foundItem: ItemDto
	product: {
		colorId: string
		sizeId: string
		productId: string
		look: string
	}
}

export const useNotificationHandler = () => {
	const { isSmall, isMedium } = useResponsive()
	const { navigate } = useNavigation()
	const dispatch = useCartDispatch()
	const { showPreview, showShoppingCart, smallSnackbar } = useCartState()

	const {
		addItemNotification,
		addItemWithNatigationNotification,
		addLargeNotification,
		addItemTextNotification,
	} = useNotifications()

	const getDispatch = () => {
		if (!showPreview && !showShoppingCart) {
			dispatch({ showPreview: 'auto' })
		}
	}

	const handleClickFullpageButton = () => {
		navigate(CART_PATH)
	}

	const handleNotification = ({
		location,
		notShowNotification,
		totalItems,
		variant,
		foundItem,
	}: NotificationHandlerProps) => {
		if (
			location.includes(PageType.ShoppingCart) &&
			(variant === FEEDBACK_ADD_TO_CART_VARIANTS.VARIANT_1 ||
				variant === FEEDBACK_ADD_TO_CART_VARIANTS.VARIANT_2)
		) {
			addItemTextNotification()
			return
		}

		if (
			location.includes(PageType.ShoppingCart) &&
			variant === FEEDBACK_ADD_TO_CART_VARIANTS.DEFAULT
		) {
			addItemNotification()
			return
		}

		switch (variant) {
			case FEEDBACK_ADD_TO_CART_VARIANTS.DEFAULT: {
				if ((isSmall || isMedium) && !notShowNotification) {
					addItemNotification()
				}
				getDispatch()
				break
			}
			case FEEDBACK_ADD_TO_CART_VARIANTS.VARIANT_1:
				addItemWithNatigationNotification({
					totalItems,
					onClick: handleClickFullpageButton,
				})
				break
			case FEEDBACK_ADD_TO_CART_VARIANTS.VARIANT_2:
				if (smallSnackbar) {
					addItemWithNatigationNotification({
						totalItems,
						onClick: handleClickFullpageButton,
					})
				} else {
					addLargeNotification({
						childrenProps: {
							foundItem,
							isSmall,
						},
					})
				}
				break
			default:
				break
		}
	}

	return { handleNotification }
}
