import type { MngItem } from 'analytics/types/EEC'

import type { ItemType } from '../services/graphql.types'

export function getMngItems(items: ItemType[] | undefined): MngItem[] {
	return (
		items?.map((itemMapped) => ({
			item_id: itemMapped?.productId?.toString(),
			item_category2: itemMapped?.sizeId?.toString(),
			item_variant: itemMapped?.colorId,
			quantity: itemMapped?.quantity,
		})) || []
	)
}
