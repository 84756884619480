import { graphql } from 'codegen/src/gateway'

export const findByUser = graphql(`
	query FindByUser(
		$channel: String!
		$orderBy: ShoppingCartOrderByInput
		$canApplyPromoCode: Boolean
	) {
		findByUser(
			channel: $channel
			orderBy: $orderBy
			canApplyPromoCode: $canApplyPromoCode
		) {
			... on ShoppingCartDto {
				totalItems
				deliveries {
					order
					items {
						...FragmentItemsFields
					}
				}
				items {
					...FragmentItemsFields
				}
				summary {
					...FragmentSummaryDtoFields
				}
				promotion {
					...FragmentPromotionDtoFields
				}
				metadata {
					limitsExceeded
				}
				merged
			}
		}
	}
`).toString()

export const getBagItems = graphql(`
	query FindTotalItemsByUser($channel: String!) {
		findTotalItemsByUser(channel: $channel) {
			totalItems
		}
	}
`).toString()
