import type { ShoppingCartDto } from 'codegen/src/gateway/graphql'
import { useSession } from 'session/src/hooks/useSession'
import { useSWRConfig } from 'swr'

import { useStoragePreference } from 'cache/hooks/useStoragePreference/useStoragePreference'
import { TOTAL_ITEMS_KEY } from '../../constants/constants'
import { findByUser, getBagItems } from '../../services/queries'
import { shoppingCartCache } from '../../utils/cache'

/**
 * Updates the number of items in the cart
 */
export const useUpdateBag = () => {
	const { mutate } = useSWRConfig()
	const { token } = useSession()
	const { storagePreference } = useStoragePreference()

	const updateBag = async (response: ShoppingCartDto) => {
		await shoppingCartCache({ storagePreference }).addKeyToCache(
			TOTAL_ITEMS_KEY,
			response.totalItems
		)
		mutate(
			[getBagItems],
			{ findTotalItemsByUser: { totalItems: response.totalItems } },
			{ revalidate: false }
		)
		mutate(
			[findByUser, undefined, token],
			{ findByUser: response },
			{ revalidate: false }
		)
	}

	return { updateBag }
}
