export namespace CartProductException {
	export class Create extends Error {
		constructor() {
			super('Cart product invalid params')
		}
	}

	export class InfoParams extends Error {
		constructor() {
			super('Invalid info params')
		}
	}

	export class StockParams extends Error {
		constructor() {
			super('Invalid stock params')
		}
	}

	export class QuantityParams extends Error {
		constructor() {
			super('Error setting quantity')
		}
	}
}
