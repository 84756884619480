'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { useCanSendAnalytics } from 'analytics/hooks/useCanSendAnalytics/useCanSendAnalytics'
import { useEECEvents } from 'analytics/hooks/useEECEvents/useEECEvents'
import type { ItemWithPrices } from 'analytics/utils/getEECItems/getEECItems'

import { BagErrorTypes, GenericEvents } from '../analytics/events'
import type { SHOPPING_CART_LOCATION } from '../constants/constants'
import type { ItemDto } from '../services/graphql.types'
import type { CartProduct } from '../types/CartProduct'

interface GetItemsProps {
	cartItems: ItemDto[] | undefined
}

function getItems({ cartItems }: GetItemsProps): ItemWithPrices[] {
	return cartItems
		? cartItems.map((item: ItemDto): ItemWithPrices => {
				return {
					productId: item.productId,
					colorId: item.colorId,
					quantity: item.quantity,
					lookId: item.look,
					index: 1,
					sizeId: item.sizeId,
					currentPrice: item.price.current?.currentPrice ?? 0,
					originalPrice:
						item.price.original?.shop?.amount ??
						item.price.initial?.initialPrice ??
						0,
				}
			})
		: []
}

export const useAnalytics = () => {
	const { sendViewCart, sendBeginCheckout, sendSelectItem } = useEECEvents()
	const { canSendAnalytics } = useCanSendAnalytics()

	return {
		sendViewCartEECEvent: async (
			bagItemNumber: number,
			location: SHOPPING_CART_LOCATION,
			cartItems: ItemDto[] | undefined
		): Promise<void> => {
			if (canSendAnalytics) {
				const items: ItemWithPrices[] = getItems({
					cartItems,
				})
				sendViewCart({
					bagItemNumber,
					location,
					items,
				})
			}
		},
		sendBeginCheckoutEECEvent: async (
			location: SHOPPING_CART_LOCATION,
			cartItems: ItemDto[] | undefined
		): Promise<void> => {
			const items: ItemWithPrices[] = getItems({
				cartItems,
			})
			sendBeginCheckout({
				location,
				items,
			})
		},
		sendSelectItemEECEvent: async (
			location: SHOPPING_CART_LOCATION,
			cartItem: CartProduct
		): Promise<void> => {
			const [item]: ItemWithPrices[] = getItems({
				cartItems: [cartItem],
			})
			sendSelectItem({
				location,
				item,
			})
		},
		sendBagUpdateEvent: () => {
			sendGenericEvent(GenericEvents.BAG_UPDATE)
		},
		sendBagErrorAddItemEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.ADD_ITEM,
			})
		},
		sendBagErrorRemoveItemEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.REMOVE_ITEM,
			})
		},
		sendBagErrorClearItemEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.CLEAR_ITEM,
			})
		},
		sendBagErrorTotalItemsEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.TOTAL_ITEMS,
			})
		},
		sendBagErrorPreviewItemsEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.PREVIEW_ITEMS,
			})
		},
		sendBagErrorPageItemsEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.PAGE_ITEMS,
			})
		},
		sendBagStockTotalErrorEvent: (
			action: 'show' | 'click_CTA' | 'click_screen'
		) => {
			sendGenericEvent(GenericEvents.STOCK_TOTAL_ERROR, {
				action,
			})
		},
		sendBagStockPartialErrorEvent: (
			action: 'show' | 'click_beginCheckout' | 'click_returnBag'
		) => {
			sendGenericEvent(GenericEvents.STOCK_PARTIAL_ERROR, {
				action,
			})
		},
		sendAddPromoCodeEvent({ code }: { code: string }) {
			sendGenericEvent(GenericEvents.ADD_PROMO_CODE, {
				type: code,
			})
		},
	}
}
