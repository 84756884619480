import { useSWRConfig } from 'swr'

import { useStoragePreference } from 'cache/hooks/useStoragePreference/useStoragePreference'
import { TOTAL_ITEMS_KEY } from '../../constants/constants'
import { getBagItems } from '../../services/queries'
import { shoppingCartCache } from '../../utils/cache'

/**
 * Updates the number of items in the cart
 */
export const useUpdateTotalItems = () => {
	const { mutate } = useSWRConfig()
	const { storagePreference } = useStoragePreference()

	const updateTotalItems = (totalItems: number) => {
		shoppingCartCache({ storagePreference }).addKeyToCache(
			TOTAL_ITEMS_KEY,
			totalItems
		)
		mutate(
			[getBagItems],
			{ findTotalItemsByUser: { totalItems } },
			{ revalidate: false }
		)
	}

	return { updateTotalItems }
}
