import { useContext } from 'react'

import { CartProductContext } from '../context/CartProductProvider'

export const useCartProductContext = () => {
	const context = useContext(CartProductContext)
	if (!context) {
		throw new Error(
			'useCartProductContext must be used within a CartProductProvider'
		)
	}
	return context
}
