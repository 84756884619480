import type { ItemDto as GatewayItemDto } from 'codegen/src/gateway/graphql'

import type { ItemDto, PriceDto } from '../services/graphql.types'

export function transformGatewayItemToItemDto(
	gatewayItem: GatewayItemDto
): ItemDto {
	const localWarehouses =
		gatewayItem.warehouses?.filter((w): w is string => Boolean(w)) ?? []

	const localItem: ItemDto = {
		productId: gatewayItem.productId,
		colorId: gatewayItem.colorId,
		sizeId: gatewayItem.sizeId,
		customized: gatewayItem.customized,
		quantity: gatewayItem.quantity,
		seller: gatewayItem.seller,
		itemId: gatewayItem.itemId,
		look: gatewayItem.look ?? '',
		price: gatewayItem.price as PriceDto,
		outOfStock: gatewayItem.outOfStock ?? false,
		hasLowStock: gatewayItem.hasLowStock,
		warehouses: localWarehouses,
	}

	return localItem
}
