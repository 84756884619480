import type { ShoppingCartDto } from 'codegen/src/gateway/graphql'

import type { ProductType } from '../hooks/mutations/useAddToCart'

export const findItemHandler = (
	response: ShoppingCartDto,
	productData: Partial<ProductType>
) => {
	return response?.items?.find(
		(item) =>
			item?.look === productData?.look &&
			item?.productId === productData.productId &&
			item?.sizeId === productData.sizeId &&
			item?.colorId === productData.colorId
	)
}
