import { Channel } from 'types/masterData'

export const handlePreviousPrices = (
	shopPreviousPrice: number | undefined,
	outletPreviousPrice: number | undefined,
	channel: Channel,
	hasPromoCode: number | null | undefined,
	originalPrice: number | undefined
): number | undefined => {
	if (channel === Channel.Shop) {
		return shopPreviousPrice ?? (hasPromoCode ? originalPrice : undefined)
	}

	if (channel === Channel.Outlet) {
		return outletPreviousPrice ?? (hasPromoCode ? originalPrice : undefined)
	}

	return undefined
}
