import { ButtonPrimary } from '@fukku/button/Primary'
import { CART_PATH } from 'cart/constants/constants'
import { useBagTotalItems } from 'cart/hooks/queries/useBagTotalItems'
import { useCartProduct } from 'cart/hooks/useCartProduct'
import { useInfo } from 'cart/hooks/useInfo'
import text from 'fukku/styles/texts.module.scss'
import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import Image from 'next/image'
import type { ProductImage } from 'product/types'
import { useState } from 'react'
import { cx } from 'utils/cx'
import { Price } from '../../../../shoppingCart/components/Price/Price'
import styles from './AddedToCartNotificationContent.module.scss'

export const AddedToCartNotificationContent = () => {
	const { cartProduct } = useCartProduct()
	const { totalItems } = useBagTotalItems()
	const { t } = useLabels()
	const { navigate } = useNavigation()
	const { productInfo } = useInfo(cartProduct.productId)
	const [loaded, setLoaded] = useState(false)

	const typeImageDefault = 'B'

	const color = productInfo?.colors?.find(
		({ id }: { id: string }) => id === cartProduct.colorId
	)
	let imageToShow: ProductImage = { img: '', alt: '' }

	if (color) {
		const { images } = color.looks?.[cartProduct.look] || {}
		const image = images?.[typeImageDefault]
		if (image) {
			imageToShow = { img: image.img, alt: image.alt }
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.body}>
				{imageToShow.img && (
					<div className={styles.imageWrapper}>
						<Image
							alt={cartProduct?.info?.imageAlt ?? ''}
							data-testid='bag.preview.item.image'
							src={imageToShow.img}
							width={100}
							height={140}
							onLoad={() => setLoaded(true)}
							className={cx(styles.image, loaded && styles['image--animated'])}
						/>
					</div>
				)}
				<div className={styles.details}>
					<p className={styles.productName}>{cartProduct?.info?.name}</p>
					<div className={styles.price}>
						<Price
							productId={cartProduct.productId}
							colorId={cartProduct.colorId}
							price={cartProduct.price}
							promoCode={undefined}
							textStyle={text.bodyM}
							horizontal
						/>
					</div>
					<p className={styles.size}>{cartProduct?.info?.sizeName}</p>
				</div>
			</div>
			<div className={styles.buttonWrapper}>
				<ButtonPrimary
					fullWidth
					className={styles.button}
					onClick={() => {
						navigate(CART_PATH)
					}}
				>
					{replacePlaceholders(t('notification.addToBag.button'), {
						1: totalItems ?? 0,
					})}
				</ButtonPrimary>
			</div>
		</div>
	)
}
