'use client'

import { getFavorites } from 'favorite/hooks/utils/getFavorites'
import { getFavoritesStorage } from 'favorite/utils/favoritesCache'

import { FAVORITES_LIST_KEY } from '../constants/constants'
import type { FavoriteProductResponse } from '../types/types'
import { getCacheFavorites } from './getCacheFavorites'

interface InitializeFavoritesProps {
	token: string
	logged: boolean
	hasLoggedChangedValue?: boolean
}

export const initializeFavorites = async ({
	token,
	logged,
	hasLoggedChangedValue = false,
}: InitializeFavoritesProps): Promise<FavoriteProductResponse[]> => {
	const cacheFavorites = await getCacheFavorites(logged)

	if (cacheFavorites && !hasLoggedChangedValue) {
		return cacheFavorites
	}

	if (!logged) {
		await getFavoritesStorage(logged)?.addKeyToCache(FAVORITES_LIST_KEY, [])
		return []
	}

	const userFavorites = await getFavorites(token)
	await getFavoritesStorage(logged)?.addKeyToCache(
		FAVORITES_LIST_KEY,
		userFavorites
	)

	return userFavorites
}
