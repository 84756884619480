import { pushToDatalayer } from 'analytics/utils/pushToDatalayer/pushToDatalayer'
import type { Channel } from 'types/masterData'

import { getOmnibusAnalytics } from '../utils/getOmnibusAnalytics/getOmnibusAnalytics'

const EVENT = 'GA4-generic-clickInfoOmnibus'
const EVENT_NAME = 'click_info_omnibus'

export const sendPriceAnalytics = (
	pricesHistory: number[],
	hideSaleOrPromoPrice: boolean,
	isHightlighted: boolean,
	channel: Channel
) => {
	const { priceOmnibus, priceOmnibusVsCurrentPrice } = getOmnibusAnalytics(
		pricesHistory,
		channel
	)

	const omnibusAnalyticInfo = `${priceOmnibus},${priceOmnibusVsCurrentPrice},${isHightlighted}`

	const eventPayload = {
		event: EVENT,
		event_name: EVENT_NAME,
		click_info_omnibus: {
			genesis: true,
			label_1: hideSaleOrPromoPrice
				? omnibusAnalyticInfo
				: 'non-historical-prices',
		},
	}
	pushToDatalayer(eventPayload)
}
