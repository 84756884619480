'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSession } from 'session/src/hooks/useSession'
import { type KeyedMutator, default as useSwrImmutable } from 'swr'

import type { Storage } from 'cache/constants/constants'
import { useStoragePreference } from 'cache/hooks/useStoragePreference/useStoragePreference'
import { TOTAL_ITEMS_KEY } from '../../constants/constants'
import { useCartState } from '../../context/CartProvider'
import { getBagItems } from '../../services/queries'
import { shoppingCartCache } from '../../utils/cache'
import { useUpdateTotalItems } from '../mutations/useUpdateTotalItems'
import { useCartFetcher } from '../useCartFetcher'

export interface CartDataType {
	findTotalItemsByUser: {
		totalItems: number
	}
}

interface UseBagTotalItemsType {
	totalItems: number | undefined
	mutate: KeyedMutator<CartDataType>
	error: Error[] | null
}

interface CacheFetcherProps {
	token: string
	countryISO: string
	storagePreference: Storage
}

interface UseBagTotalItemsProps {
	errorCallback?: () => void
}

const cacheFetcher = async (args: CacheFetcherProps) => {
	const cacheInstance = shoppingCartCache({
		token: args.token,
		countryISO: args.countryISO,
		storagePreference: args.storagePreference,
	})
	const totalItemsCache: string | undefined =
		await cacheInstance.getKeyFromCache<string>(TOTAL_ITEMS_KEY)
	if (totalItemsCache !== undefined) {
		return totalItemsCache
	}
	throw new Error('no totalItem in cache')
}

export const useBagTotalItems = ({
	errorCallback,
}: UseBagTotalItemsProps = {}): UseBagTotalItemsType => {
	const { showShoppingCart } = useCartState()
	const { fetcher } = useCartFetcher<CartDataType, undefined>()
	const { updateTotalItems } = useUpdateTotalItems()
	const { token } = useSession()
	const { storagePreference } = useStoragePreference()

	const {
		country: { countryISO },
	} = useMasterData()

	// query to handle cache storage total items
	useSwrImmutable(
		token && countryISO ? { token, countryISO, storagePreference } : null,
		cacheFetcher,
		{
			onSuccess: (cacheData) => updateTotalItems(Number(cacheData)),

			onError: () => {
				if (
					!showShoppingCart &&
					data?.findTotalItemsByUser?.totalItems === undefined
				) {
					mutate()
				}
			},
		}
	)

	const { data, error, mutate } = useSwrImmutable<CartDataType>(
		token ? [getBagItems] : null,
		fetcher,
		{
			revalidateOnMount: false,
			onSuccess: (newData) => {
				updateTotalItems(newData?.findTotalItemsByUser?.totalItems)
			},
			onError: () => {
				if (errorCallback) {
					errorCallback()
				}
			},
		}
	)

	return {
		totalItems: data?.findTotalItemsByUser?.totalItems,
		error,
		mutate,
	}
}
