'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'

import styles from '../DiscountRate/DiscountRate.module.scss'

export type ExtraInfoButtonProps = {
	readonly handleOnClick: () => void
}

export const ExtraInfoButton = ({ handleOnClick }: ExtraInfoButtonProps) => {
	const { t } = useLabels()

	return (
		<button
			type='button'
			aria-label={t('accessibility.moreInfo.icon')}
			onClick={handleOnClick}
			className={styles.buttonAccessibility}
		/>
	)
}
