import type { Product } from 'product/types'

import { useCartDispatch } from '../context/CartProvider'
import type { CartProduct } from '../types/CartProduct'
import { useCartProductContext } from './useCartProductContext'
import { useInfo } from './useInfo'
import { useNotifications } from './useNotifications'

export const useCartProduct = (): {
	cartProduct: CartProduct
	product: Product | undefined
	error: Error | null
	minimized: boolean
} => {
	const { cartProduct, minimized } = useCartProductContext()
	const { productInfo, error } = useInfo(cartProduct.productId)
	const { itemWithParamErrorNotification } = useNotifications()
	const dispatch = useCartDispatch()

	if (error) {
		itemWithParamErrorNotification()
		dispatch({
			visibleItemErrorBanner: true,
		})
	}

	return { cartProduct, error, minimized, product: productInfo }
}
