import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSetSession } from 'session/src/hooks/useSetSession'
import { type CartDispatch, useCartDispatch } from '../context/CartProvider'
import { openLimitWarningModal } from '../context/LimitsWarning'
import { useAnalytics } from '../hooks/useAnalytics'
import type { PurchaseType } from '../services/graphql.types'
import { useNotifications } from './useNotifications'

export interface ErrorItem {
	message: string
	path?: string[]
	extensions?: Extensions
}

interface Extensions {
	exception?: Exception
	oneItemShoppingCartOnly?: boolean
	shoppingCart?: PurchaseType | null
}

interface Exception {
	path: string[]
	message: string
}

interface UseErrorManagement {
	addErrorManagement: (errors: ErrorItem | ErrorItem[]) => void
}

interface AddErrorHandlersType {
	error: ErrorItem
	dispatch: CartDispatch
	environment?: string
}

/**
 * Hook that handles the errors of the shopping cart.
 */
export const useErrorManagement = (): UseErrorManagement => {
	const dispatch = useCartDispatch()
	const {
		addItemNotificationError,
		itemWithoutStockNotification: itemWithoutStockNotificationFn,
	} = useNotifications()
	const { handleSession } = useSetSession()
	const { sendBagErrorAddItemEvent } = useAnalytics()

	const { environment } = useMasterData()

	const addErrorHandlers = (
		itemWithoutStockNotification: () => void
	): {
		[key: string]: (param: AddErrorHandlersType) => void
	} => ({
		MaxNumberOfItems: ({ dispatch: cartDispatch }: AddErrorHandlersType) => {
			openLimitWarningModal(cartDispatch)
		},
		AmountLimit: ({ dispatch: cartDispatch }: AddErrorHandlersType) => {
			openLimitWarningModal(cartDispatch)
		},
		ItemWithoutStock: () => {
			itemWithoutStockNotification()
		},
		MalformedToken: () => {
			handleSession()
		},
		TokenExpired: () => {
			handleSession()
		},
		AddItem: ({ error, environment: env }: AddErrorHandlersType) => {
			if (env !== 'pro') {
				const { exception } = error.extensions || {}

				window.alert(`
					IMPORTANT: This error message only appears on non-production environments, as it needs to be addressed by UX.
					Meanwhile, this will serve for debugging purposes.
					Error: ${exception?.message}
					At path: ${exception?.path}
				`)
			}
		},
	})

	const getPath = (error: ErrorItem): string[] => {
		const { path } = error.extensions?.exception ?? error
		return path || ['']
	}

	/**
	 * Manage errors that can occur during the add to cart process.
	 */
	const addErrorManagement = (errors: ErrorItem | ErrorItem[]): void => {
		const errorHandlers = addErrorHandlers(itemWithoutStockNotificationFn)
		sendBagErrorAddItemEvent()

		if (!Array.isArray(errors)) {
			// Generic error for mobile when errors is not an array
			addItemNotificationError()
			return
		}

		errors.forEach((error) => {
			const type = Object.keys(errorHandlers).find((errorKey) =>
				getPath(error).includes(errorKey)
			)
			const handler = type ? errorHandlers[type] : null
			if (handler) {
				handler({ error, dispatch, environment })
			} else {
				addItemNotificationError()
			}
		})
	}

	return { addErrorManagement }
}
