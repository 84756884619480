'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import type { ReactNode } from 'react'
import type { Channel } from 'types/masterData'
import { applyStylesIf, cx } from 'utils/cx'

import { SinglePrice } from '../../SinglePrice/SinglePrice'
import type { BodyContentProps } from '../Content/BodyContent'
import { getTooltipPricesKeys } from './keys'

import text from 'fukku/styles/texts.module.scss'
import styles from '../ExtraInfo.module.scss'

const lastElement = -1

export type PricesHistoryProps = {
	readonly pricesHistory: number[]
	readonly getLabel: BodyContentProps['getLabel']
	readonly channel: Channel
}

export function PricesHistory({
	pricesHistory,
	getLabel,
	channel,
}: PricesHistoryProps): ReactNode {
	const { country } = useMasterData()
	const keysToUse = getTooltipPricesKeys(channel, pricesHistory)

	return (
		<ul className={styles.pricesList}>
			{pricesHistory.map((price, index) => {
				const isCrossedOut = pricesHistory.at(lastElement) !== price
				const labelKey = keysToUse[index]
				const finalPrice = index === pricesHistory.length - 1

				return (
					<li className={cx(text.bodyS, styles.pricesHistory)} key={labelKey}>
						<span>{getLabel(labelKey)}</span>
						<SinglePrice
							key={labelKey}
							price={price}
							label={getLabel(labelKey)}
							align={'start'}
							dataTestid='currentPrice'
							isCrossedOut={isCrossedOut}
							priceClassName={cx(
								applyStylesIf(finalPrice, 'pricesHistoryFinalPrice')
							)}
							country={country}
						/>
					</li>
				)
			})}
		</ul>
	)
}
