import { graphql } from 'codegen/src/gateway'

export const addItem = graphql(`
	mutation AddItem(
		$channel: String!
		$item: ShoppingCartAddItemInput!
		$orderBy: ShoppingCartOrderByInput
		$canApplyPromoCode: Boolean
	) {
		addItem(
			channel: $channel
			item: $item
			orderBy: $orderBy
			canApplyPromoCode: $canApplyPromoCode
		) {
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			promotion {
				...FragmentPromotionDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()

export const removeItem = graphql(`
	mutation RemoveItem(
		$channel: String!
		$itemId: String!
		$orderBy: ShoppingCartOrderByInput
		$canApplyPromoCode: Boolean
	) {
		removeItem(
			channel: $channel
			itemId: $itemId
			orderBy: $orderBy
			canApplyPromoCode: $canApplyPromoCode
		) {
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			promotion {
				...FragmentPromotionDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()

export const clearItem = graphql(`
	mutation ClearItem(
		$channel: String!
		$itemId: String!
		$orderBy: ShoppingCartOrderByInput
		$canApplyPromoCode: Boolean
	) {
		clearItem(
			channel: $channel
			itemId: $itemId
			orderBy: $orderBy
			canApplyPromoCode: $canApplyPromoCode
		) {
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			promotion {
				...FragmentPromotionDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()

export const applyShoppingCartPromotionalCode = graphql(`
	mutation ApplyShoppingCartPromotionalCode(
		$orderBy: ShoppingCartOrderByInput
		$promotionalCodeInput: ShoppingCartPromotionalCodeInput!
	) {
		applyShoppingCartPromotionalCode(
			orderBy: $orderBy
			promotionalCodeInput: $promotionalCodeInput
		) {
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			promotion {
				...FragmentPromotionDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()

export const cancelShoppingCartPromotionalCode = graphql(`
	mutation CancelShoppingCartPromotionalCode(
		$channel: String!
		$orderBy: ShoppingCartOrderByInput
	) {
		cancelShoppingCartPromotionalCode(channel: $channel, orderBy: $orderBy) {
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			promotion {
				...FragmentPromotionDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()
