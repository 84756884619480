import type { Product } from 'product/types'
import type { Stock } from 'product/types/Stock'
import {
	type ReactElement,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import type { ItemDto } from '../services/graphql.types'
import { CartProduct } from '../types/CartProduct'
import { useCartDispatch } from './CartProvider'

export interface CartProductContextProps {
	cartProduct: CartProduct
	setInfo: (product: Product) => void
	setStock: (product: Stock) => void
	minimized: boolean
}

interface CartProductProviderProps {
	readonly item: ItemDto
	readonly children: ReactElement<CartProduct>
	readonly minimized?: boolean
}

export const CartProductContext = createContext<CartProductContextProps | null>(
	null
)

export const CartProductProvider = ({
	item,
	children,
	minimized = false,
}: CartProductProviderProps): JSX.Element => {
	const dispatch = useCartDispatch()
	const [cartProduct, setCartProduct] = useState<CartProduct>(
		CartProduct.create(item)
	)

	const setInfo = (product: Product) => {
		try {
			setCartProduct(cartProduct.setInfo(product))
		} catch {
			dispatch({
				visibleItemErrorBanner: true,
			})
		}
	}

	const setStock = (stock: Stock) => {
		setCartProduct(cartProduct.setStock(stock))
	}

	const setQuantity = (quantity: number) => {
		setCartProduct(cartProduct.setQuantity(quantity))
	}

	useEffect(() => {
		if (item.quantity !== cartProduct.quantity) {
			setQuantity(item.quantity)
		}
	}, [item.quantity])

	const value = useMemo(
		() => ({
			cartProduct,
			setInfo,
			setStock,
			minimized,
		}),
		[item, cartProduct]
	)

	return (
		<CartProductContext.Provider value={value}>
			{children}
		</CartProductContext.Provider>
	)
}
