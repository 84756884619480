'use client'
import { getFavoritesStorage } from 'favorite/utils/favoritesCache'
import { initializeFavorites } from 'favorite/utils/initializeFavorites'
import { type MutableRefObject, useEffect } from 'react'
import { useSession } from 'session/src/hooks/useSession'
import { isBot } from 'utils/isBot/IsBot'

import type { StorageClass } from 'cache/domain/Storage'
import { useStoragePreference } from 'cache/hooks/useStoragePreference/useStoragePreference'
import { FAVORITES_LIST_KEY } from '../constants/constants'
import type { FavoriteProductResponse } from '../types/types'
import { useSyncFavorites } from './useSyncFavorites'
import { hasLoggedChanged, setIsLogged } from './utils/hasLoggedChanged'

interface UseInitializeFavoritesProps {
	favoritesEnabled: boolean
	hasInitialized: MutableRefObject<boolean>
	onInitialitze(favorites: FavoriteProductResponse[]): void
}

export function useInitializeFavorites({
	favoritesEnabled,
	hasInitialized,
	onInitialitze,
}: UseInitializeFavoritesProps) {
	const { isReady, token, logged } = useSession()
	const { syncFavorites } = useSyncFavorites()
	const { storagePreference } = useStoragePreference()

	useEffect(() => {
		if (!(favoritesEnabled && isReady && token)) {
			return
		}

		const hasLoggedChangedValue = hasLoggedChanged(logged)
		if (hasInitialized.current && !hasLoggedChangedValue) {
			return
		}

		hasInitialized.current = true
		const favoritesStorage: StorageClass | null = getFavoritesStorage(
			logged,
			token,
			storagePreference
		)

		const init = async () => {
			let favorites: FavoriteProductResponse[] = []

			try {
				if (isBot() || (hasLoggedChangedValue && !logged)) {
					favorites = []
					await favoritesStorage?.addKeyToCache(FAVORITES_LIST_KEY, [])
					setIsLogged(logged)
				} else {
					if (hasLoggedChangedValue) {
						await syncFavorites()
						await favoritesStorage?.addKeyToCache(FAVORITES_LIST_KEY, null)
						setIsLogged(logged)
					}

					favorites = await initializeFavorites({
						token,
						logged,
						hasLoggedChangedValue,
					})
				}
			} finally {
				onInitialitze(favorites)
			}
		}
		init()
	}, [isReady, token, logged])
}
