import { getStorageStrategy } from 'cache/cache'
import type { Storage } from 'cache/constants/constants'
import type { StorageClass } from 'cache/domain/Storage'
import type { StorageProps } from 'cache/types/types'
import { CACHE_KEY, EXPIRED } from 'favorite/constants/constants'

let cacheInstance: StorageClass | null = null

export const getCacheInstance = () => cacheInstance

export const getFavoritesStorage = (
	logged = false,
	token?: string,
	storagePreference?: Storage
): StorageClass | null => {
	if (cacheInstance) {
		return cacheInstance
	}

	if (token) {
		const payload: StorageProps = {
			cacheKey: CACHE_KEY,
			storagePreference,
		}
		if (logged) {
			payload.maxDiffTimestamp = EXPIRED
			payload.token = token
		}
		cacheInstance = getStorageStrategy(payload)
	}

	return cacheInstance
}

export const resetFavoritesCache = () => {
	cacheInstance = null
}
