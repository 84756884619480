import { Channel } from 'types/masterData'

export function getOmnibusAnalytics(
	pricesHistory: number[],
	channel: Channel
): {
	priceOmnibus: boolean
	priceOmnibusVsCurrentPrice: 'lower' | 'higher' | null
} {
	const fourPrices = 4
	const threePrices = 3
	const indexTwo = 2
	const indexThree = 3

	if (pricesHistory.length === fourPrices) {
		// This is when channel is Outlet, is the only channel with 4 prices
		return {
			priceOmnibus: true,
			priceOmnibusVsCurrentPrice:
				pricesHistory[indexTwo] > pricesHistory[indexThree]
					? 'higher'
					: 'lower',
		}
	}
	if (channel === Channel.Shop && pricesHistory.length === threePrices) {
		return {
			priceOmnibus: true,
			priceOmnibusVsCurrentPrice:
				pricesHistory[1] > pricesHistory[indexTwo] ? 'higher' : 'lower',
		}
	}

	return {
		priceOmnibus: false,
		priceOmnibusVsCurrentPrice: null,
	}
}
