import { getStorageStrategy } from 'cache/cache'
import type { Storage } from 'cache/constants/constants'
import type { StorageClass } from 'cache/domain/Storage'

import { CACHE_KEY } from '../constants/constants'

let cacheInstance: StorageClass | null = null

interface ShoppingCartCacheProps {
	token?: string
	countryISO?: string
	storagePreference?: Storage
}

export const shoppingCartCache = ({
	token,
	countryISO,
	storagePreference,
}: ShoppingCartCacheProps = {}): StorageClass => {
	if (!cacheInstance) {
		cacheInstance = getStorageStrategy({
			token,
			countryISO,
			storagePreference,
			cacheKey: CACHE_KEY,
			maxDiffTimestamp: 60000,
			isTokenCache: false,
			isCountryCache: false,
		})
	}
	return cacheInstance
}
