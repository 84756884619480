import { useNavigation } from 'hooks/useNavigation/useNavigation'

import type { ShoppingCartDto } from 'codegen/src/gateway/graphql'
import { useCartDispatch, useCartState } from '../context/CartProvider'
import type { ControllerType } from '../types/ControllerType'
import { refresh } from '../utils/refresh'
import { transformGatewayItemToItemDto } from '../utils/transformGatewayItemToItemDto'
import { type ProductType, useAddToCart } from './mutations/useAddToCart'
import { useBagTotalItems } from './queries/useBagTotalItems'
import { useFeedbackTestVariable } from './useFeedbackTest'
import { useNotificationHandler } from './useNotificationHandler'

export const useCartController = (isCartPage: boolean): ControllerType => {
	const cartState = useCartState()
	const { showShoppingCart, showPreview } = cartState
	const { totalItems } = useBagTotalItems()
	const dispatch = useCartDispatch()
	const { navigate } = useNavigation()
	const { addItem } = useAddToCart(isCartPage)
	const { variant } = useFeedbackTestVariable()
	const { handleNotification } = useNotificationHandler()

	const handleToggle = (): boolean => {
		if (!showShoppingCart) {
			dispatch({ showPreview: !showPreview })
			return true
		}
		return false
	}

	const handleOpen = () => {
		if (!showPreview && !showShoppingCart) {
			dispatch({ showPreview: true })
		}
	}

	const handleClose = () => {
		dispatch({ showPreview: false })
	}

	const addItemWithNotification = async (
		product: ProductType,
		location: string,
		productListId?: string
	) => {
		const response = await addItem(product, location, productListId)
		const foundItem = (response?.addItem as ShoppingCartDto)?.items?.find(
			(currentItem) =>
				currentItem?.colorId === product.colorId &&
				currentItem?.sizeId === product.sizeId &&
				currentItem?.productId === product.productId
		)

		if (!foundItem) {
			return null
		}

		const item = transformGatewayItemToItemDto(foundItem)

		handleNotification({
			foundItem: item,
			totalItems: response?.addItem?.totalItems,
			location,
			variant,
			product,
		})

		return item
	}

	return {
		status: !!showPreview,
		toggle: handleToggle,
		open: handleOpen,
		close: handleClose,
		totalItems: totalItems ?? 0,
		refresh: () => refresh(navigate),
		add: async (product, location, productListId) => {
			try {
				await addItemWithNotification(product, location, productListId)
			} catch (error) {
				console.error('Error adding product:', error)
			}
		},
		addWithoutNotification: async (product, location, productListId) => {
			try {
				await addItem(product, location, productListId)
			} catch (error) {
				console.error('Error adding product:', error)
			}
		},
	}
}
