'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useProductInfo } from 'product/services/productInfo/client'
import type { Product } from 'product/types'
import { useEffect } from 'react'

import { useCartProductContext } from './useCartProductContext'

export const useInfo = (
	productId: string
): {
	productInfo: Product | undefined
	error: Error | null
	isLoading: boolean
} => {
	const {
		country: { countryISO, languageISO },
	} = useMasterData()
	const { cartProduct, setInfo } = useCartProductContext()

	const { data, isLoading, error } = useProductInfo({
		productId,
		country: countryISO,
		language: languageISO,
		enabled: !!productId,
	})

	useEffect(() => {
		if (data && cartProduct && !cartProduct.info) {
			setInfo(data)
		}
	}, [data])

	return {
		error,
		isLoading,
		productInfo: data,
	}
}
