import { useEECEvents } from 'analytics/hooks/useEECEvents/useEECEvents'
import type {
	AddItemMutation,
	ShoppingCartAddItemInput,
	ShoppingCartDto,
} from 'codegen/src/gateway/graphql'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { DEFAULT_LOOK_ID } from 'product/constants/constants'
import type { Dispatch, SetStateAction } from 'react'

import { DEFAULT_SELLER } from '../../constants/constants'
import { useCartDispatch, useCartState } from '../../context/CartProvider'
import type { ItemType } from '../../services/graphql.types'
import { addItem as addItemQuery } from '../../services/mutations'
import type { Item } from '../../types/ControllerType'
import { checkItemStock } from '../../utils/checkItemStock'
import { findItemHandler } from '../../utils/findItem'
import { getMngItems } from '../../utils/getMngItems'
import { useCartFetcher } from '../useCartFetcher'
import { type ErrorItem, useErrorManagement } from '../useErrorManagement'
import { useUpdateBag } from './useUpdateBag'

export interface ProductType {
	collectionId?: string
	colorId: string
	customColor?: string
	customPosition?: string
	customSize?: string
	customType?: string
	look: string
	productId: string
	needleWork?: string
	sizeId: string
	seller: string
	lastUnits: boolean
	layoutView?: string
}

interface AddItemVariable {
	item: ShoppingCartAddItemInput
	canApplyPromoCode: boolean
}

export const useAddToCart = (isCartPage: boolean) => {
	const { showPreview } = useCartState()
	const dispatch = useCartDispatch()
	const { addErrorManagement } = useErrorManagement()
	const {
		country: { countryISO },
	} = useMasterData()
	const { fetcher } = useCartFetcher<AddItemMutation, AddItemVariable>()

	const { updateBag } = useUpdateBag()
	const { sendAddToCart } = useEECEvents()

	const getLookId = (lookId: string) => lookId?.toString() || DEFAULT_LOOK_ID

	const setPreventClose = () => {
		if (showPreview && !isCartPage) {
			dispatch({ preventClose: true })
		}
	}

	const prepareItem = (product: ProductType) => {
		const {
			collectionId,
			colorId,
			customColor,
			customPosition,
			customSize,
			customType,
			look,
			productId,
			needleWork,
			sizeId,
			seller = DEFAULT_SELLER,
		} = product
		const customized = {
			collectionId: collectionId?.toString(),
			customColor,
			customPosition,
			customSize,
			customType,
			needleWork,
		}

		const item: Item = {
			colorId,
			productId,
			seller,
			sizeId,
			look: getLookId(look),
		}

		if (
			customized &&
			Object.values(customized).some((attribute) => !!attribute)
		) {
			item.customized = customized
		}
		return item
	}

	const addItem = async (
		product: ProductType,
		location: string,
		productListId?: string,
		notShowNotification = false,
		updateQuantitySelector?: Dispatch<SetStateAction<number>>
	) => {
		setPreventClose()

		try {
			const item = prepareItem(product)
			await checkItemStock(item, countryISO)

			const response = await fetcher([
				addItemQuery,
				{ item, canApplyPromoCode: isCartPage ?? false },
			])

			await updateBag(response.addItem as ShoppingCartDto)

			sendAddToCart({
				item: {
					...item,
					lastUnits: product.lastUnits,
					quantity: 1,
					index: 1,
				},
				mngItems: getMngItems(response.addItem?.items as ItemType[]),
				listId: productListId,
				location,
			})

			if (notShowNotification && updateQuantitySelector) {
				const itemSelected = findItemHandler(
					response?.addItem as ShoppingCartDto,
					product
				)
				if (itemSelected?.quantity) {
					updateQuantitySelector(itemSelected?.quantity)
				}
			}

			return response
		} catch (error) {
			addErrorManagement(
				Array.isArray(error) ? (error as ErrorItem[]) : (error as ErrorItem)
			)
			return null
		}
	}

	return { addItem }
}
