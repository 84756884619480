import type { PriceDto } from 'cart/services/graphql.types'
import type { ItemPromotion } from 'codegen/src/gateway/graphql'
import { ClientProductPrice } from 'fukku/Price/client'
import type { ReactNode } from 'react'

import { parsePriceData } from '../../utils/parsePriceData'

import text from 'fukku/styles/texts.module.scss'
import styles from './Price.module.scss'

interface PriceProps {
	readonly productId: string
	readonly colorId: string
	readonly price: PriceDto
	readonly promoCode: ItemPromotion | undefined
	readonly shouldRevalidate?: boolean
	readonly textStyle?: string
	readonly horizontal?: boolean
}

export const Price = ({
	productId,
	colorId,
	price,
	promoCode,
	shouldRevalidate = false,
	textStyle = text.titleM,
	horizontal = false,
}: PriceProps): ReactNode => {
	const parsedPrices = parsePriceData(price, promoCode)

	return (
		<ClientProductPrice
			showDiscount={false}
			align='center'
			product={productId}
			color={colorId}
			prices={parsedPrices}
			shouldRevalidate={shouldRevalidate}
			horizontal={horizontal}
			classes={{
				price: textStyle,
				discountRate: textStyle,
				additionalPrice: styles.hidden,
			}}
		/>
	)
}
