import type {
	Product,
	ProductImage,
	ProductImageType,
	ProductSize,
} from 'product/types'
import type { Stock, StockAvailability } from 'product/types/Stock'

import type { ItemDto, PriceDto, ProductInfo } from '../services/graphql.types'
import { CartProductException } from './CartProductException'

const LOOK_IMAGE_DEFAULT = '00'

const TYPE_IMAGES_DEFAULT: string[] = ['F', 'O1', 'B']

export class CartProduct {
	private constructor(
		public readonly productId: string,
		public readonly colorId: string,
		public readonly sizeId: string,
		public readonly look: string,
		public readonly customized: boolean,
		public readonly quantity: number,
		public readonly price: PriceDto,
		public readonly outOfStock: boolean,
		public readonly hasLowStock: boolean,
		public readonly warehouses: string[],
		public readonly itemId: string,
		public readonly seller: string,
		public readonly info?: ProductInfo,
		public readonly stock?: StockAvailability
	) {}

	public static create(props: ItemDto): CartProduct {
		try {
			return new CartProduct(
				props.productId,
				props.colorId,
				props.sizeId,
				props.look || LOOK_IMAGE_DEFAULT,
				props.customized,
				props.quantity,
				props.price,
				props.outOfStock,
				props.hasLowStock,
				props.warehouses,
				props.itemId,
				props.seller
			)
		} catch {
			throw CartProductException.Create
		}
	}

	public setInfo(item: Product): CartProduct {
		try {
			const color = item.colors.find(({ id }) => id === this.colorId)
			let imageToShow: ProductImage = { img: '', alt: '' }
			let size: ProductSize | undefined

			if (color) {
				const { images } = color.looks[this.look]
				TYPE_IMAGES_DEFAULT.forEach((defaultType: string) => {
					const image: ProductImage | undefined =
						images[defaultType as ProductImageType]
					if (image && imageToShow.img === '') {
						imageToShow = image
					}
				})

				size = color.sizes.find(({ id }) => id === this.sizeId)
			}

			const info: ProductInfo = {
				colorId: this.colorId,
				colorName: color?.label ?? '',
				image: imageToShow.img,
				imageAlt: imageToShow.alt,
				name: item.name,
				sizeId: this.sizeId,
				sizeName: size?.label ?? '',
				genderId: item.genderId,
			}

			return new CartProduct(
				this.productId,
				this.colorId,
				this.sizeId,
				this.look,
				this.customized,
				this.quantity,
				this.price,
				this.outOfStock,
				this.hasLowStock,
				this.warehouses,
				this.itemId,
				this.seller,
				info,
				this.stock
			)
		} catch {
			throw CartProductException.InfoParams
		}
	}

	public setStock(stock: Stock): CartProduct {
		try {
			const productStock: StockAvailability =
				stock.colors[this.colorId]?.sizes[this.sizeId]

			return new CartProduct(
				this.productId,
				this.colorId,
				this.sizeId,
				this.look,
				this.customized,
				this.quantity,
				this.price,
				this.outOfStock,
				this.hasLowStock,
				this.warehouses,
				this.itemId,
				this.seller,
				this.info,
				productStock
			)
		} catch {
			throw CartProductException.StockParams
		}
	}

	public setQuantity(quantity: number): CartProduct {
		try {
			return new CartProduct(
				this.productId,
				this.colorId,
				this.sizeId,
				this.look,
				this.customized,
				quantity,
				this.price,
				this.outOfStock,
				this.hasLowStock,
				this.warehouses,
				this.itemId,
				this.seller,
				this.info,
				this.stock
			)
		} catch {
			throw CartProductException.QuantityParams
		}
	}
}
