import { CartProductProvider } from 'cart/context/CartProductProvider'
import type { ItemDto } from 'cart/services/graphql.types'
import type React from 'react'

import { AddedToCartNotificationContent } from './AddedToCartNotificationContent/AddedToCartNotificationContent'

interface AddedToCartNotificationProps {
	readonly foundItem: ItemDto
}

export const AddedToCartNotification: React.FC<
	AddedToCartNotificationProps
> = ({ foundItem }) => {
	return (
		<CartProductProvider key={foundItem.productId} item={foundItem}>
			<AddedToCartNotificationContent />
		</CartProductProvider>
	)
}
