'use client'

import { Tooltip, type TooltipHandle } from 'fukku/Tooltip/Tooltip'
import { useDocumentDirection } from 'hooks/useDocumentDirection/useDocumentDirection'
import { IconInfoL } from 'icons/components/IconInfoL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect, useRef, useState } from 'react'
import { cx } from 'utils/cx'

import { BodyContent } from '../Content/BodyContent'
import { PRICES_TOOLTIP_TITLE } from '../Content/keys'
import type { PricesHistoryComponentProps } from '../ExtraInfo'
import { ExtraInfoButton } from '../ExtraInfoButton'

import styles from './TooltipPricesHistory.module.scss'

const MARGIN_TOOLTIP = 8

export function TooltipPricesHistory({
	pricesHistory,
	shouldShowDiscount,
	horizontal,
	sendAnalytics,
}: Readonly<PricesHistoryComponentProps>) {
	const tooltipRef = useRef<TooltipHandle | null>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const [tooltipShow, setTooltipShow] = useState<boolean>(false)
	const { isRtl } = useDocumentDirection()

	useEffect(() => {
		if (tooltipShow) {
			tooltipRef.current?.open()
		} else {
			tooltipRef.current?.close()
		}
	}, [tooltipShow])

	const { channel } = useMasterData()
	const { t } = useLabels()

	const reverse = isRtl ? !horizontal : horizontal

	const boundary = {
		current: {
			getBoundingClientRect: () => ({
				left: 0,
				right:
					(containerRef.current?.getBoundingClientRect()?.right ?? 0) +
					MARGIN_TOOLTIP,
			}),
		} as HTMLDivElement,
	}

	const handleOnClick = () => {
		sendAnalytics()
		setTooltipShow(!tooltipShow)
	}

	return (
		<div className={styles.container}>
			<ExtraInfoButton handleOnClick={handleOnClick} />
			<div ref={containerRef}>
				<Tooltip
					data-testid='tooltip.history.prices'
					ref={tooltipRef}
					className={styles.tooltip}
					boundary={boundary}
					preventHover
					reverse={reverse}
					content={
						<BodyContent
							channel={channel}
							getLabel={t}
							pricesHistory={pricesHistory}
							shouldShowDiscount={shouldShowDiscount}
							title={PRICES_TOOLTIP_TITLE}
						/>
					}
				>
					<div className={cx(styles.icon, !horizontal && styles.iconVertical)}>
						<IconInfoL height={12} width={12} />
					</div>
				</Tooltip>
			</div>
		</div>
	)
}
