'use client'

import { useContext } from 'react'

import {
	type SessionActions,
	SetSessionContext,
} from '../contexts/SessionProvider'

/**
 * @throws {Error}
 */

export const useSetSession = (): SessionActions => {
	const sessionActions = useContext(SetSessionContext)

	if (!(sessionActions?.setSession || sessionActions?.handleSession)) {
		throw new Error('useSetSession must be used within a SessionProvider')
	}

	return sessionActions
}
